import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Button, TextField, Typography, InputAdornment } from '@material-ui/core';
import { RemoveRedEye } from '@material-ui/icons';
import { navigate } from 'gatsby'
import Layout from '../components/Layout'
import { signup, clear_error } from '../redux/auth.redux';

const styles = theme => ({
	container: {
			display: 'block',
			textAlign: 'center',
			margin: 'auto',
	},
	paper: {
			margin: 'auto',
			width: '350px',
			padding: 30,
	},
	textField: {
		marginLeft: theme.spacing.unit,
		marginRight: theme.spacing.unit,
		width: 280,
	},
	button: {
		marginTop: 30,
		marginBottom: 30,
	},
	header: {
		marginTop: 30,
	},
});

class Signup extends React.Component {

	constructor(props) {
		super(props);
		this.auth = {email: null, password: null, firstName: null, lastName: null, surffix: null, username: null};
		this.state = {err: null, showPassword: null};
	}

	componentDidMount() {
		if (typeof window === 'undefined')
			return;
		if (this.props.location.state && this.props.location.state.destination) {
			this.setState(() => ({ destination: this.props.location.state.destination }));
		}
	}

	componentDidUpdate() {
        if (this.props.auth.isLoggedIn) navigate(this.state.destination ? this.state.destination : '/');
        if (this.props.auth.err) {
            if (this.props.auth.err.includes('duplicate key')) {
                if (confirm('Are you our customer or in our mailist already? System will redirect you to retrieve your account.')) {
                    this.props.clear_error();
                    navigate('/forgotPassword');
                }
            } else {
                alert(this.props.auth.err);
            }
        }
	}

	render() {
		const { classes } = this.props;
		function validateEmail (email) {
			const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			return regexp.test(email);
		}
		function validatePassword (password) {
			const regexp = /^(?=.*[A-Za-z])(?=.*[0-9])(?=.{8,})/
			return regexp.test(password);
		}
		const setFirstname = (input) => {
			this.auth.firstName = input;
		}

		const setLastname = (input) => {
			this.auth.lastName = input;
		}

		const setUsername = (input) => {
			this.auth.username = input;
		}

		const handlePasswordMask = () => {
			this.setState((state) => ({showPassword: !state.showPassword}));
		}

		const setEmail = (input) => {
			this.auth.email = input;
		}
		const setPassword = (input) => {
			this.auth.password = input;
		}

		const signup = () => {
            if (!this.auth.firstName) {
                this.setState({err: 'Firstname is required'});
                return;
            }
            if (!this.auth.lastName) {
                this.setState({err: 'Lastname is required'});
                return;
            }
			if (!this.auth.username) {
				this.setState({err: 'Plase enter a username'});
				return;
            }
            
			if (!validateEmail(this.auth.email)) {
				this.setState({err: 'Plase enter an valid email address'});
				return;
			}
			if (!validatePassword(this.auth.password)) {
				this.setState({err: 'Password is too weak, it must have more than 8 characters, contain at least 1 letter and 1 digit.'});
				return;
			}  
			this.props.signup(this.auth);
			
		}

		return (
			<Layout hideSocial={true}>
				<span className={classes.paper}>
					<form  className={classes.container}>
						<Typography className={classes.header} variant='h5' color='primary'>Welcome to Sonic Instruments!</Typography>
						<div>
							<TextField
								id="standard-firstname"
								label="Firstname"
								className={classes.textField}
								onChange={(event) => setFirstname(event.target.value)}
								margin="normal"
							/>
						</div>
						<div>
							<TextField
								id="standard-lastname"
								label="Lastname"
								className={classes.textField}
								onChange={(event) => setLastname(event.target.value)}
								margin="normal"
							/>
						</div>
						<div>
							<TextField
								id="standard-username"
								label="Username"
								className={classes.textField}
								onChange={(event) => setUsername(event.target.value)}
								margin="normal"
							/>
						</div>
						<div>
							<TextField
								id="standard-email"
								label="Email"
								className={classes.textField}
								onChange={(event) => setEmail(event.target.value)}
								margin="normal"
							/>
						</div>
						<div>
							<TextField
								id="standard-password-input"
								label="Password"
								className={classes.textField}
								type={this.state.showPassword ? 'text' : 'password'}
								autoComplete="current-password"
								margin="normal"
								onChange={(event) => setPassword(event.target.value)}
								InputProps={{
									endAdornment: (
									  <InputAdornment position="end">
										<RemoveRedEye onClick={handlePasswordMask}/>
									  </InputAdornment>
									),
								}}
							/>
						</div>
						<div>
							<Button className={classes.button} variant="contained" size="medium" color="primary" onClick={()=>signup()}> Signup </Button>
						</div>
						<div>
							<Typography color='primary' gutterBottom={true}><a href="/login">Already have an account?</a></Typography>
						</div>
						<div>
							<Typography color='secondary' gutterBottom={true} style={{margin:40}}>{this.state.err}</Typography>
						</div>
					</form>
				</span>
			</Layout>
		);
	}
	
}

Signup.propTypes = {
	classes: PropTypes.object.isRequired,
}

const mapStatetoProps = (state) => {return { auth : state.auth }};
const actionCreators = { signup, clear_error };
export default connect(mapStatetoProps, actionCreators)(withStyles(styles)(Signup));